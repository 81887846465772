<template>
  <div class="partnero-container">
    <div
      class="background-banner"
    >
      <background-banner />
    </div>

    <div
      v-if="infoPartnero && infoPartnero.partner"
      class="joined-partnero-container"
    >
      <div class="mr-50">
        <feather-icon
          icon="InfoIcon"
          size="24"
          class="text-blue"
        />
      </div>
      <div>
        <h4 class="text-blue">
          {{ $t('referral.titleYouHaveJoinedProgram') }}
        </h4>
        <p class="mb-0">{{ $t('referral.subYouHaveJoinedProgram') }} <a
          :href="partneroLoginUrl"
          target="_blank"
          class="btn-click-here text-blue"
        >{{ $t('login.btnSignIn') }} {{ $t('referral.now') }}</a></p>
      </div>
    </div>

    <b-row
      id="programs"
      class="mt-1 p-1"
    >
      <b-col class="tabs-mf tabs-partnero px-0">
        <b-tabs>
          <b-tab
            id="affiliate-terms"
            :title="$t('referral.tabAffiliateTerms.titleAffiliateTerms')"
          >
            <affiliate-teams-tab />
          </b-tab>
          <b-tab
            id="our-community"
            :title="$t('referral.tabOurCommunity.titleOurCommunity')"
          >
            <our-community-tab />
          </b-tab>
          <b-tab
            v-if="showOldReferralProgramTab"
            id="old-referral-program"
            :title="$t('referral.textOldReferralProgram')"
          >
            <old-referral-program />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _isEmpty from 'lodash/isEmpty'
import envMixin from '@/mixins/envMixin'
import AffiliateTeamsTab from './components/AffiliateTermsTab.vue'
import OurCommunityTab from './components/OurCommunityTab.vue'
import BackgroundBanner from './components/BackgroundBanner.vue'
import OldReferralProgram from './components/OldReferralProgram.vue'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  components: {
    OldReferralProgram,
    AffiliateTeamsTab,
    OurCommunityTab,
    BackgroundBanner,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  mixins: [envMixin],
  data() {
    return {
      infoPartnero: null,
      showOldReferralProgramTab: false,
    }
  },

  computed: {
    ...mapGetters(['user']),
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data) {
          const currentCreatedAt = new Date(userInfo?.data?.createdAt)
          const releasedDate = new Date('2024-03-18T00:00:00.001Z')
          this.showOldReferralProgramTab = currentCreatedAt < releasedDate

          if (!_isEmpty(userInfo?.data?.partnero)) {
            this.infoPartnero = userInfo?.data?.partnero
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.tabs-partnero {
  .nav-tabs {
    margin-bottom: 30px;
  }
}
</style>

<style scoped lang="scss">
.partnero-container {
  padding: 0 15px;

  .background-banner{
    // padding: 20px;
  }
}

.joined-partnero-container {
  background: rgba(38, 103, 255, 0.10);
  padding: 15px;
  display: flex;
  border-radius: 20px;
  margin: 30px 0 0;

  .btn-click-here {
    text-decoration: underline;
    font-family: "ProximaNovaA-Medium", Helvetica, Arial, serif;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
