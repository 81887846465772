<template>
  <div class="old-referral-container">
    <div class="icon-info">
      <feather-icon
        icon="InfoIcon"
        size="24"
      />
    </div>
    <p>{{ $t('referral.txtIfYouWerePart') }} <span
      class="btn-click-here"
      @click.prevent="goToOldReferral"
    >{{
      $t('common.linkClickHere')
    }}</span> {{ $t('referral.txtToViewYourReferralLink') }}</p>
  </div>
</template>

<script>
export default {

  methods: {
    goToOldReferral() {
      this.$router.push('/referral-introduce')
    },
  },
}
</script>

<style scoped lang="scss">
.old-referral-container {
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: rgba(38, 103, 255, 0.10);

  .icon-info {
    margin-right: 12px;
  }

  .btn-click-here {
    cursor: pointer;
    font-family: "ProximaNovaA-medium", Helvetica, Arial, serif;
    text-decoration: underline;
    color: #2667FF;

    &:hover{
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }
}
</style>
