<template>
  <b-modal
    ref="modal-join-partnero-success"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
    size="lg"
  >
    <div class="d-flex justify-content-end top-modal">
      <div>
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="d-flex flex-column justify-content-between align-items-center">
      <b-img
        class="icon-mailbox"
        :src="require('@/assets/images/pages/partnero/ic-mailbox.png')"
      />

      <h3 class="title-notification">
        {{ $t('referral.tabOurCommunity.titleModalJoinSuccess') }}
      </h3>

      <p class="font-14 m-0">
        {{ $t('referral.tabOurCommunity.textTheAccountActivationLink') }} <span class="content-email-join">{{ mailJoined }}</span>.
      </p>
      <p class="font-14 m-0 content-join-success">{{ $t('referral.tabOurCommunity.textPleaseCheckYourInbox') }}
        <span class="mb-0">
          <user-hint
            :hide-icon="true"
            :hide-quote="true"
          />
        </span>
        {{ $t('referral.tabOurCommunity.textTeamIfYouDoNot') }}</p>

      <div class="list-platform-contact">
        <p class="title-contact-with">
          {{ $t('referral.tabOurCommunity.textOpenYourEmail') }}.
        </p>
        <div
          class="list-contact-mail"
        >
          <div
            v-for="(item, index) in listMailContact"
            :key="index"
            class="item-mail"
            @click.prevent="goToMail(item.linkMail)"
          >
            <b-img
              :src="item.icon"
              alt="icon contact"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */

import {
  BModal,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import UserHint from '@/components/UserHint.vue'

export default {
  components: {
    UserHint,
    BModal,
    BImg,
  },

  directives: {
    Ripple,
  },

  props: {
    mailJoined: {
      type: String || null,
      default: null,
    },
  },

  data() {
    return {
      listMailContact: [
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-email.png'),
          linkMail: 'https://mail.google.com/mail/u/0/#inbox',
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-outlook.png'),
          linkMail: 'https://outlook.live.com/mail/0/',
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-mail.png'),
          linkMail: 'https://www.icloud.com/mail',
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-zoho-mail.png'),
          linkMail: 'https://www.zoho.com/vi/mail/',
        },
      ],
    }
  },

  methods: {
    goToSupport() {
      if (window.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },

    goToMail(url) {
      window.open(url)
    },

    hideModal() {
      this.$refs['modal-join-partnero-success'].hide()
    },

    showModal() {
      this.$refs['modal-join-partnero-success'].show()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill';

.modal-body {
  padding: 40px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.icon-mailbox {
  margin-bottom: 20px;
}

.title-notification {
  margin-bottom: 12px;
  font-size: 24px;
}

.list-platform-contact {
  margin-top: 30px;

  .title-contact-with {
    font-size: 16px;
    font-family: "ProximaNovaA-Medium", Helvetica, Arial, serif;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 30px;
    text-align: center;
  }
}

.content-email-join {
  background: linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%);
  background-clip: text !important;
  color: transparent;
}

.list-contact-mail {
  display: flex;
  align-items: center;

  .item-mail {
    padding: 20px;
    margin: 0 20px;
    border-radius: 20px;
    border: 1px solid #81FFF4;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.contact-live-chat {
  text-decoration: underline;
  cursor: pointer;
}

.content-join-success{
  text-align: center;
}
</style>
